import { BooleanInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, Filter, SelectInput, useGetIdentity } from 'react-admin';
import DomainFilter from '../DomainFilter';
import SearchFilter from '../SearchFilter';

const DeviceFilter = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  let platformEnum = [
    { id: 'ios', name: translate('ios') },
    { id: 'android', name: translate('android') },
  ];

  return (
    <Filter {...props}>
      {identity?.user?.isAdmin && (
        <DomainFilter source="rootDomain" label={translate('rootDomain')} />
      )}

      {identity?.user?.isAdmin && (
        <DomainFilter source="domain" label={translate('domain')} />
      )}

      {identity?.user?.isAdmin && (
        <SearchFilter
          label={translate('searchUuid')}
          source="uuid_contains"
          {...props}
          alwaysOn
        />
      )}

      {identity?.user?.isAdmin && (
        <SearchFilter
          label={translate('searchToken')}
          source="token_contains"
          {...props}
          alwaysOn
        />
      )}

      <BooleanInput
        source="notification"
        allowEmpty={true}
        helperText={false}
      />
      <SelectInput
        source="platform"
        label={translate('platform')}
        choices={platformEnum}
        allowEmpty={false}
        defaultValue={'published'}
        style={{ width: 250 }}
        helperText={false}
      />
    </Filter>
  );
};

export default DeviceFilter;
